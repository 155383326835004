<template>
  <div>
    <div class="flexs">
      <sidebarUserInfo style="flex: 0 0 300px; height: fit-content" />
      <div style="flex: 1">
        <top-banner class="banners" />
          <dealer/>
<!--          <div class="top-lists">-->
<!--              <div-->
<!--                      class="nav-view"-->
<!--                      @click="$router.push(item.url)"-->
<!--                      :class="index == 4 ? 'styles' : ''"-->
<!--                      v-for="(item, index) in navList"-->
<!--              >-->
<!--                  {{ item.name }}-->
<!--              </div>-->
<!--          </div>-->

      </div>
    </div>
  </div>
</template>

<script>
import dealer from "@/components/dealer";
import * as apis from "@/api/index.js";
import sidebarUserInfo from "@/components/sidebarUserInfo";
import topBanner from "@/components/topBanner";
import * as api from "@/api/register";
import { getBankCardChoiceList } from "@/api/wallet";
import { getToken } from "@/util/auth";
export default {
  data() {
    return {
      navList: [
          { name: "调换货物", url: "/" },
          { name: "修改地址", url: "/" },
          { name: "订单自提", url: "/" },
          { name: "户籍变更", url: "/" },
          { name: "经销商认证", url: "/dealer" },
          { name: "超级工作室认证", url: "/superStudio" },
          { name: "创客空间认证", url: "/markerSpace" },
      ],
      status_flag:true,
      text: '提交认证',
      storeTypes: 3,
      // 多张图
      fileList1: [],
      disableds: false,
      flags: 0,
      hideUploadAdd: false,
      regionParams: {
        label: "name",
        value: "id",
        children: "children",
        expandTrigger: "hover",
      },
      //上传后的文件列表
      fileList: [],
      fileType: ["png", "jpg"],
      fileSize: 50,
      fileLimit: 50,
      fileLimits: 50,
      headers: {
        Authorization: getToken(),
      },
      areaData: [],
      status: null, //0未上传过 1已上传正在系统审核 2审核已成功只能查看无法编辑 3审核被驳回
      table: {
        storeType:3,
        // storeName: "", //名称
        // storeLevelVal: "", //创客空间等级
        licenseName: "", //营业执照名称
        creditCode: "", //统一社会信用代码
        legalName: "", //法人姓名
        legalIdCard: "", //法人身份证号
        licenseProvince: "", //营业执照省
        licenseCity: "", //营业执照市
        licenseCounty: "", //营业执照区
        licenseAddress: "", //营业执照详细地址
        storePerson: "", //联系人
        storePhone: "", //联系方式
        pkBank: "", //银行名称
        bankAccount: "", //银行账号
        storeProvince: "", //省
        storeCity: "", //市
        storeCounty: "", //区
        storeAddress: "", //详细地址
        // storeSign: "", // 创客空间标志
        legalCardFront: "", //法人身份证正面
        legalCardBack: "", // 法人身份证反面
        license: "", // 营业执照
        storeAgreementList: [], // 协议列表
        // videoDoorhead: "", // 门店门头视频
        // videoStore: "", // 门店店内视频
        // videoProduct: "", // 门店产品视频
        // imageDoorhead1: "", // 门店门头图片1
        // imageDoorhead2: "", // 门店门头图片2
        // imageExperience1: "", // 门店体验区图片1
        // imageExperience2: "", // 门店体验区图片2
        // imageMeeting1: "", // 门店会议室图片1
        // imageMeeting2: "", // 门店会议室图片2
        // imageProduct1: "", // 门店产品图片1
        // imageProduct2: "", // 门店产品图片2
        // imageLounge1: "", // 门店休息室图片1
        // imageLounge2: "", // 门店休息室图片2
        // bankBranch: "",//开户支行
      },
      address:'',//营业执照地址
      address1:'',//详细地址
      bankCardChioceList: [],
      rules: {
        storeName: [
          { required: true, message: "请输入经销商名称", trigger: "blur" },
        ],
        licenseName: [
          { required: true, message: "请输入营业执照名称", trigger: "blur" },
        ],
        creditCode: [
          { required: true, message: "请输入统一社会信用代码", trigger: "blur" },
        ],
        legalName: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
        ],
        legalIdCard: [
          { required: true, message: "请输入法人身份证号", trigger: "blur" },
        ],
        licenseProvince: [
          { required: true, message: "请选择营业执照省市区", trigger: ['blur', 'change'] },
        ],
        licenseAddress: [
          { required: true, message: "请输入营业执照详细地址", trigger: "blur" },
        ],
        storeAddress: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        storeProvince: [
          { required: true, message: "请选择省市区", trigger: ['blur', 'change'] },
        ],
        pkBank: [
          { required: true, message: "请选择银行名称", trigger: "change" },
        ],
        bankAccount: [
          { required: true, message: "请输入银行账号", trigger: "blur" },
        ],
        bankBranch: [
          { required: true, message: "请输入开户支行", trigger: "blur" },
        ],
        storePerson: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        storePhone: [
          {
            required: true,
            message: this.$t('w_0053'),
            trigger: 'blur',
          },
          { min: 11, message: '请输入正确的手机号', trigger: 'blur' },
        ],
        storeSign: [
          { required: true, message: " ", trigger: "blur" },
        ],
      },
      storeLevelVal:'',
      memberCode:'',
      memberName:'',
      cause:'', //驳回原因
      creatTime:'', // 创建时间
      checkTime:'', //审核时间
    };
  },
  components: { sidebarUserInfo, topBanner,dealer },
  watch: {},
  mounted() {
    this.getAllArea();
    this.getBankCardChoiceList();
    this.getUserStatus();
  },
  methods: {
    checks(check) {
      if(check==1){
        this.status = 0
        this.flags = 0
        this.disableds = true
        this.status_flag = false
      }
      if(check==2){
        this.text = '提交认证'
        this.status = 0
        this.flags = 0
      }
    },
    submit() {
      this.$refs['ruleForm'].validate(valid => {
        if (!valid) {
          return false
        }else{
          if(this.table.legalCardFront=='' || this.table.legalCardFront.trim().length ===0){
            this.$message({
              message: "请上传法人身份证正面",
              type: "warning",
            });
            return
          }
          if(this.table.legalCardBack=='' || this.table.legalCardBack.trim().length ===0){
            this.$message({
              message: "请上传法人身份证反面",
              type: "warning",
            });
            return
          }
          if(this.table.license=='' || this.table.license.trim().length ===0){
            this.$message({
              message: "请上传营业执照",
              type: "warning",
            });
            return
          }
          if(this.table.storeAgreementList.length==0){
            this.$message({
              message: "请上传已签字的经销商协议",
              type: "warning",
            });
            return
          }
          api.markerSpaceSubmit(this.table).then((res)=>{
            if(res.code=='200'){
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.getUserStatus();
            }
          })
        }
      })
    },
    setAddress(res) {
      this.table.licenseProvince = res[0];
      this.table.licenseCity = res[1];
      this.table.licenseCounty = res[2];
    },
    setAddress1(res) {
      this.table.storeProvince = res[0];
      this.table.storeCity = res[1];
      this.table.storeCounty = res[2];
    },
    remove1() {
      console.error("000");
    },
    change1() {
      console.error("000");
    },
    upload1(file) {
      console.error(file);
      console.error("00");
    },
    newUploadImg(index, item) {
      this.imgUrl = "";
      this.$refs["upload"].$refs["upload-inner"].handleClick();
    },
    delStop(index) {
      let data = {
        storeType: this.storeTypes,
        filePath: this.table.storeAgreementList[index],
        fileType: 14,
      };
      api.delImg(data).then((res) => {
        if (res.code == "200") {
          this.table.storeAgreementList.splice(index, 1);
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    delStops(item, num) {
      const time = item;
      let data = {
        storeType: this.storeTypes,
        filePath: this.table[item],
        fileType: num,
      };
      api.delImg(data).then((res) => {
        if (res.code == "200") {
          this.table[item] = "";
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    getUserStatus() {
      api.markerSpaceDetail({ storeType: this.storeTypes }).then((res) => {
          console.error(res.data.approveStatus)
        if(res.data.approveStatus==undefined){
          this.status = 0
        }else{
          this.status = res.data.approveStatus
          if (res.data.approveStatus != 0) {
            this.flags = 1;
          }
            if(res.data.applyTime){
                this.creatTime = res.data.applyTime
            }
            if(res.data.approveTime){
                this.checkTime = res.data.approveTime
            }
          if(this.status>0){
            let arr=[];
            let arr1=[];
            if(res.data.storeProvince){
              arr1.push(res.data.storeProvince.toString())
              arr1.push(res.data.storeCity.toString())
              if(res.data.storeCounty){
                arr1.push(res.data.storeCounty.toString())
              }
            }
            if(res.data.licenseProvince){
              arr.push(res.data.licenseProvince.toString())
              arr.push(res.data.licenseCity.toString())
              if(res.data.licenseCounty){
                arr.push(res.data.licenseCounty.toString())
              }
            }
            this.address = arr //营业执照省市区
            this.address1 = arr1 //联系人省市区
            this.table={
              storeType:res.data.storeType,
              // storeName: res.data.storeName, //名称
              // storeLevelVal: res.data.storeLevelVal, //创客空间等级
              licenseName: res.data.licenseName, //营业执照名称
              creditCode:res.data.creditCode, //统一社会信用代码
              legalName: res.data.legalName, //法人姓名
              legalIdCard: res.data.legalIdCard, //法人身份证号
              licenseProvince: res.data.licenseProvince, //营业执照省
              licenseCity: res.data.licenseCity, //营业执照市
              licenseCounty: res.data.licenseCounty, //营业执照区
              licenseAddress: res.data.licenseAddress, //营业执照详细地址
              storePerson:res.data.storePerson, //联系人
              storePhone: res.data.storePhone, //联系方式
              pkBank: res.data.pkBank, //银行名称
              bankAccount: res.data.bankAccount, //银行账号
              storeProvince: res.data.storeProvince, //省
              storeCity: res.data.storeCity, //市
              storeCounty: res.data.storeCounty, //区
              storeAddress: res.data.storeAddress, //详细地址
              // storeSign:res.data.storeSign?res.data.storeSign:'', // 创客空间标志
              legalCardFront: res.data.legalCardFront, //法人身份证正面
              legalCardBack: res.data.legalCardBack, // 法人身份证反面
              license: res.data.license, // 营业执照
              storeAgreementList: res.data.storeAgreementList, // 协议列表
              // videoDoorhead: res.data.videoDoorhead, // 门店门头视频
              // videoStore: res.data.videoStore, // 门店店内视频
              // videoProduct: res.data.videoProduct, // 门店产品视频
              // imageDoorhead1: res.data.imageDoorhead1, // 门店门头图片1
              // imageDoorhead2: res.data.imageDoorhead2, // 门店门头图片2
              // imageExperience1: res.data.imageExperience1, // 门店体验区图片1
              // imageExperience2: res.data.imageExperience2, // 门店体验区图片2
              // imageMeeting1: res.data.imageMeeting1, // 门店会议室图片1
              // imageMeeting2: res.data.imageMeeting2, // 门店会议室图片2
              // imageProduct1: res.data.imageProduct1, // 门店产品图片1
              // imageProduct2: res.data.imageProduct2, // 门店产品图片2
              // imageLounge1: res.data.imageLounge1, // 门店休息室图片1
              // imageLounge2: res.data.imageLounge2, // 门店休息室图片2
              bankBranch: res.data.bankBranch //支行
            }

            this.storeLevelVal = res.data.storeLevelVal;
            this.memberCode = res.data.memberCode;
            this.memberName = res.data.memberName;
          }
        }
      });
    },
    getAllArea() {
      api.allArea().then((res) => {
        this.areaData = res.data;
      });
    },
    getBankCardChoiceList() {
      getBankCardChoiceList().then((res) => {
        this.bankCardChioceList = res.data;
      });
    },
    handleRemove() {},
    // 文件状态改变时的钩子
    handleChange(file, fileList) {
      // 文件数量改变

      this.fileList = fileList;
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 2MB!");
        this.fileList.pop();
      }
      return isLt2M;
    },
    handleClose(i) {
      this.fileList.splice(i, 1); //删除上传的文件
      if (this.fileList.length == 0) {
        //如果删完了
        this.fileflag = true; //显示url必填的标识
        this.$set(this.rules.url, 0, {
          required: true,
          validator: this.validatorUrl,
          trigger: "blur",
        }); //然后动态的添加本地方法的校验规则
      }
    },
    handleExceed() {
      // this.$message({
      //   type:'warning',
      //   message:'超出最大上传文件数量的限制！'
      // });return
    },
    //多张图上传方法
    uploadFile1(item) {
      const fileName = item.file.name;
      const fileType = fileName.substring(fileName.lastIndexOf("."));
      if (fileType !== ".jpg" && fileType !== ".png") {
        this.$message({
          message: "图片格式仅支持jpg，png",
        });
      }
      // this.$showMessage('文件上传中........')
      let FormDatas = new FormData();
      FormDatas.append("file", item.file);
      FormDatas.append("storeType", this.storeTypes);
      FormDatas.append("fileType", 14);
      // FormDatas.append('image',result[0]);
      api.uploadImg(FormDatas).then((res) => {
        if (res.data) {
          this.table.storeAgreementList.push(res.data.url); //成功过后手动将文件添加到展示列表里
          // let i = this.fileList1.indexOf(res.data.url)
          // this.fileList1[i].id = res.data.name;//id也添加进去，最后整个大表单提交的时候需要的
        }
      });
    },
    uploadVideo(item) {
      const result = item.filename.split(","); //0名称 1列表名称
      let name = result[0];
      let img = result[1];
      const fileName = item.file.name;
      const fileType = fileName.substring(fileName.lastIndexOf("."));
      if (fileType !== ".mp4") {
        this.$message({
          message: "视频格式仅支持mp4",
        });
        return;
      }
      if (item.file.size / 1024 / 1024 > 50) {
        this.$message.warning("上传视频大于50MB，请重新上传！");
        return false;
      }
      let FormDatas = new FormData();
      FormDatas.append("file", item.file);
      FormDatas.append("storeType", this.storeTypes);
      FormDatas.append("fileType", img);
      api.uploadImg(FormDatas).then((res) => {
        if (res.data) {
          this.table[name] = res.data.url;
          console.error(this.table);
        }
      });
    },
    uploadFile(item) {
      const result = item.filename.split(","); //0名称 1列表名称
      let name = result[0];
      let img = result[1];
      const fileName = item.file.name;
      const fileType = fileName.substring(fileName.lastIndexOf("."));
      if (fileType !== ".jpg" && fileType !== ".png") {
        this.$message({
          message: "图片格式仅支持jpg，png",
        });
        return;
      }
      if (item.file.size / 1024 / 1024 > 5) {
        this.$message.warning("上传图片大于5MB，请重新上传！");
        return false;
      }
      let FormDatas = new FormData();
      FormDatas.append("file", item.file);
      FormDatas.append("storeType", this.storeTypes);
      FormDatas.append("fileType", img);
      api.uploadImg(FormDatas).then((res) => {
        if (res.data) {
          this.table[name] = res.data.url;
          console.error(this.table);
        }
      });
    },
    //上传成功后的回调
    handleSuccess() {},
  },
};
</script>
<style>
.hide .el-upload--picture-card {
  display: none !important;
}
</style>
<style lang="scss" scoped>
.styles {
  border: none !important;
  color: #fff !important;
  background: #d61820;
}
.top-lists {
  display: flex;
  padding: 20px 0 0 0;
  .nav-view {
    cursor: pointer;
    padding: 0 26px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid #999999;
    font-size: 14px;
    text-align: center;
    line-height: 44px;
    margin-right: 20px;
    color: #999;
  }
}
.width{
  position: relative;
  width: 128px;
  height: 128px;
}
.close-icon{
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
}
.idCardWidth {
  width: 362px !important;
  height: 211px !important;
  position: relative;
  top: 0;
}
.card {
  width: 362px !important;
  height: 211px !important;
  position: relative;
}
.card img {
  width: 362px !important;
  height: 211px !important;
}
.centers {
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-list {
  //margin-left: -20px;
}
::v-deep .el-upload {
  display: flex;
}
.upload-demo {
  display: flex;
}
.text_s {
  position: absolute;
  top: 70px;
  right: 20px;
  width: 360px;
  line-height: 20px;
  color: #666;
  font-size: 12px;
}
.bolds {
  font-weight: bold;
}
.status-size {
  margin-top: 10px;
  font-size: 20px;
  color: #000;
}
.img_cen {
  padding: 200px 0;
  margin: 0 auto;
  text-align: center;
}
.status-img img {
  width: 80px;
  height: 80px;
}
.button-s {
  width: 290px;
  height: 66px;
  background: #d61820;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  cursor: pointer;
  line-height: 66px;
  font-size: 22px;
  color: #fff;
  margin: 60px auto;
}
::v-deep .el-form-item {
  margin-bottom:20px;
}
.titleSize {
  margin: 10px 0 5px 0 !important;
  color: #000 !important;
}
.idCard img {
  width: 362px;
  height: 211px;
}
.line-s {
  //padding: 0 20px;
  margin: 40px 0;
  background: rgba(0, 0, 0, 0.1);
  height: 4px;
  position: absolute;
  width: 100%;
  top: 1380px;
}
.size-s {
  color: #c8161d;
  font-size: 12px;
  margin-bottom: 5px;
}
.texts {
  color: #999;
  font-size: 12px;
  margin-top: 20px;
}
.imgWidth {
  position: relative;
  width: 128px;
  height: 128px;
}
video {
  position: absolute;
  width: 128px !important;
  height: 128px !important;
  margin-top: 0 !important;
  left: 0;
  top: 0;
}
.imgWidth img {
  position: absolute;
  width: 128px !important;
  height: 128px !important;
  margin-top: 0 !important;
  left: 0;
  top: 0;
}
.box-colors {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px;
  width: 128px;
  height: 128px;
  position: absolute;
}
.img-ss {
  margin-right: 20px;
}
.img-s img {
  width: 40px;
  height: 40px;
  margin-top: 25px;
}
.upload {
  width: 128px;
  height: 128px;
  background: rgba(204, 204, 204, 0.3);
  border-radius: 6px 6px 6px 6px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 20px;
}
::v-deep .area .el-input__inner {
  width: 820px;
  height: 65px;
  font-size: 18px;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding-bottom: 0;
}
::v-deep .el-form-item__label {
  font-size: 16px;
  color: #000;
}
::v-deep .centers .el-input__inner {
  text-align: center;
}
::v-deep .inputs .el-input__inner {
  width: 378px;
  height: 65px;
  font-size: 18px;
}
.flexs {
  display: flex;
  padding-right: 60px;
  background-color: #f9f9f9;
}
.banners {
  flex: 1;
  margin-top: 20px;
}
.content {
  width: 100%;
  padding: 30px 0;
  background-color: #fff;
  margin-top: 20px;
  box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
  border-radius: 10px 10px 10px 10px;
  .systemTitle {
    display: flex;
    justify-content: space-between;
    //margin-top: 25px;
    position: relative;
    .button {
      background-color: #ffad41;
      color: #fff;
      font-size: 16px;
      height: 48px;
      border-radius: 10px 10px 10px 10px;
      line-height: 20px;
      position: absolute;
      right: 80px;
      top: 10px;
    }
    .titles {
      margin: 25px auto auto auto;
      color: #333;
      font-size: 28px;
      font-weight: 600;
    }
  }
  .title {
    //height: 49px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    padding-bottom: 10px;
    .name {
      margin-left: 10px;
      font-weight: 600;
    }
    .line {
      width: 5px;
      height: 19px;
      background: #c8161d;
    }
  }
}
</style>
